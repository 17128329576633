import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkList::Item\n  ...attributes\n  @disabled={{@disabled}}\n  @button={{@buttonVariant}}\n  @link={{unless @buttonVariant true}}\n  @route={{@route}}\n  @model={{@model}}\n  @models={{@models}}\n  @activeLinkClass={{this.classes.activeClass}}\n  @buttonClass='{{if @isActive this.classes.activeClass}}'\n  @currentWhen={{@currentWhen}}\n  @onClick={{this.onTabClick}}\n  local-class='ak-tab-item'\n  data-test-ak-tab-item\n  as |It|\n>\n\n  <It.leftIcon local-class='ak-tab-icon'>\n    {{#if (has-block 'tabIcon')}}\n      {{yield to='tabIcon'}}\n    {{/if}}\n  </It.leftIcon>\n\n  {{yield}}\n\n  {{#if @hasBadge}}\n    <AkStack\n      @tag='span'\n      @direction='row'\n      @alignItems='center'\n      @justifyContent='center'\n      local-class='ak-tab-badge'\n      data-test-ak-tab-badge\n    >\n      {{@badgeCount}}\n    </AkStack>\n  {{/if}}\n</AkList::Item>", {"contents":"<AkList::Item\n  ...attributes\n  @disabled={{@disabled}}\n  @button={{@buttonVariant}}\n  @link={{unless @buttonVariant true}}\n  @route={{@route}}\n  @model={{@model}}\n  @models={{@models}}\n  @activeLinkClass={{this.classes.activeClass}}\n  @buttonClass='{{if @isActive this.classes.activeClass}}'\n  @currentWhen={{@currentWhen}}\n  @onClick={{this.onTabClick}}\n  local-class='ak-tab-item'\n  data-test-ak-tab-item\n  as |It|\n>\n\n  <It.leftIcon local-class='ak-tab-icon'>\n    {{#if (has-block 'tabIcon')}}\n      {{yield to='tabIcon'}}\n    {{/if}}\n  </It.leftIcon>\n\n  {{yield}}\n\n  {{#if @hasBadge}}\n    <AkStack\n      @tag='span'\n      @direction='row'\n      @alignItems='center'\n      @justifyContent='center'\n      local-class='ak-tab-badge'\n      data-test-ak-tab-badge\n    >\n      {{@badgeCount}}\n    </AkStack>\n  {{/if}}\n</AkList::Item>","moduleName":"irene/components/ak-tabs/item/index.hbs","parseOptions":{"srcName":"irene/components/ak-tabs/item/index.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import styles from './index.scss';

export interface AkTabsItemSignature {
  Element: HTMLLIElement;
  Args: {
    id: number | string;
    isActive?: boolean;
    hasBadge?: boolean;
    buttonVariant?: boolean;
    badgeCount?: string | number;
    onTabClick?: (id: number | string, event: MouseEvent) => void;
    disabled?: boolean;
    route?: string;
    model?: string;
    models?: string[];
    query?: Record<string, unknown>;
    currentWhen?: string;
    indicatorVariant?: 'shadow' | 'line';
  };

  Blocks: {
    default: [];
    tabIcon: [];
  };
}

export default class AkTabsItemComponent extends Component<AkTabsItemSignature> {
  get variant() {
    return this.args.indicatorVariant || 'line';
  }

  get classes() {
    return {
      activeClass: styles[`active-${this.variant}`],
    };
  }

  @action onTabClick(event: MouseEvent) {
    if (this.args.onTabClick) {
      this.args.onTabClick(this.args.id, event);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AkTabs::Item': typeof AkTabsItemComponent;
    'ak-tabs/item': typeof AkTabsItemComponent;
  }
}
